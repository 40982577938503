import React, {ReactElement} from 'react';
import {P2pCashoutsStore} from 'stores/P2pCashoutsStore';
import {useTranslation} from 'react-i18next';

interface ScreenshotLoaderProps {
    p2pCashoutsStore: P2pCashoutsStore
}

export const ChangeStatusButtons = (props: ScreenshotLoaderProps): ReactElement => {
  const isWaiting = (): boolean => props.p2pCashoutsStore.current_p2p_cashout.state === 'waiting';
  const {t} = useTranslation();

  const {notWalidCashout, acceptTransferCashout} = props.p2pCashoutsStore;
  return <div className="ofelos-pay-confirm-transfer">
    { isWaiting() && <button
      className="ofelos-pay-button cancel"
      onClick={notWalidCashout}>
      {t('cashout_money_not_come')}
    </button>}
    <button
      className="ofelos-pay-button"
      onClick={acceptTransferCashout}>
      {t('cashout_money_come')}
    </button>
  </div>;
};

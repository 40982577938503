import React, {ChangeEvent, Component, Fragment, ReactNode} from 'react';
import Checkbox from 'components/Checkbox/Checkbox';
import {inject, observer} from 'mobx-react';
import isoCurrencyToHtml from 'utils/currencyIsoFormat/isoCurrencyToHtml';
import bankLogotypeSvg from 'images/p2p/bank-logotype.svg';
import CopyButton from 'components/CopyButton/CopyButton';
import ScreenshotLoader from 'components/ScreenshotLoader/ScreenshotLoader';
import {P2pDepositsStore} from 'stores/P2pDepositsStore';
import upToMinutes from 'utils/upToMinutes/upToMinutes';
import remainingTimeUntil15Minutes from 'utils/remainingTimeUntil15Minutes/remainingTimeUntil15Minutes';
import amountFormat from 'utils/amountFormat/amountFormat';
import {translatedKeyLangs} from 'utils/translatedKeyLangs/transaltedKeyLangs';
import P2pDepositsStoreProps from 'interfaces/P2pDepositsStoreProps';
import ScreenshotPayment from '../ScreenshotPayment';
import {ModalsControlStore} from 'stores/ModalsControlStore';
import ModalsControlStoreProps from 'interfaces/ModalsControlStoreProps';
import {StatusBar} from 'components/CurrentCashout/StatusBar/StatusBar';
import SvgSprite from 'utils/svgSprite/svgSprite';
import remainingTimeUntil30Minutes from 'utils/remainingTimeUntil30Minutes/remainingTimeUntil15Minutes';
import {withTranslation, WithTranslation} from 'react-i18next';

interface MinutesSeconds {
    minutes: number
    seconds: number
}

interface CurrentDepositState {
    remainingTime: MinutesSeconds,
    transferred: boolean,
    showModal: boolean,
}

const DEFAULT_MINUTES = 15;
const EXTRA_MINUTES = 30;
const UPDATE_TIME = 100;
const FIVE_SECOND = 5000;

@inject('p2pDepositsStore', 'modalsControlStore')
@observer
class CurrentDeposit extends Component {
  state: CurrentDepositState = {
    remainingTime: {
      minutes: DEFAULT_MINUTES,
      seconds: 0
    },
    showModal: false,
    transferred: false
  };

  interval: number | undefined;

  get p2pDepositsStore(): P2pDepositsStore {
    const {p2pDepositsStore} = this.props as P2pDepositsStoreProps;
    return p2pDepositsStore;
  }

  get modalsControlStore(): ModalsControlStore {
    const {modalsControlStore} = this.props as ModalsControlStoreProps;
    return modalsControlStore;
  }

  componentDidMount = (): void => {
    const {accepted_at} = this.p2pDepositsStore.current_p2p_deposit;

    if (!remainingTimeUntil15Minutes(accepted_at) &&
            remainingTimeUntil30Minutes(accepted_at) &&
              !this.stableStatuses) {
      this.endedTimeout();
    }
    if (this.updateRemainingTime()) {
      this.interval = window.setInterval(this.updateRemainingTime, UPDATE_TIME);
    }
  };

  componentWillUnmount = (): void => {
    // HideLastPopup('modal-confirm');
    clearInterval(this.interval);
    // Redirect to another page this.props.p2pDepositsStore.update(null);
  };

  updateRemainingTime = (): boolean => {
    const {current_p2p_deposit: {accepted_at}} = this.p2pDepositsStore;

    if (this.stableStatuses) {
      return false;
    }

    const remainingTime = upToMinutes(accepted_at, DEFAULT_MINUTES);

    if (remainingTime.minutes <= 0 && remainingTime.seconds <= 0) {
      return this.firstTimeoutEnd(accepted_at);
    }

    this.setState({remainingTime});
    return true;
  };

  firstTimeoutEnd = (accepted_at: string): boolean => {
    const remainingTime = upToMinutes(accepted_at, EXTRA_MINUTES);

    if (remainingTime.minutes === DEFAULT_MINUTES &&
            remainingTime.seconds === 0) {
      this.endedTimeout();
    }

    this.setState({remainingTime});
    if (remainingTime.minutes <= 0 && remainingTime.seconds <= 0) {
      // Delete it if add sockets
      setTimeout(() => this.p2pDepositsStore.loadCurrentP2pDeposits(), FIVE_SECOND);
      clearInterval(this.interval);
      return false;
    }
    return true;
  };

  transferred = (event: ChangeEvent<HTMLInputElement>): void => this.setState({
    transferred: event.target.checked
  });

  get stableStatuses(): boolean {
    return [
      'waiting',
      'not_valid',
      'suspended',
      'cancelled',
      'completed',
      'denied'
    ].includes(
      this.p2pDepositsStore.current_p2p_deposit.state
    );
  }

  endedTimeout = (): void => {
    const {t} = this.props as WithTranslation;
    const {confirmP2pTransfer} = this.p2pDepositsStore;
    const {modalConfirm, closeModal} = this.modalsControlStore;

    const params = {
      onCancel: {text: t('confirm_button_no_сontinue')},
      onOkClick: () => confirmP2pTransfer(closeModal),
      text: t('made_payment')
    };

    modalConfirm && modalConfirm(params);
  };

  confirmTransfer = (): void => {
    this.p2pDepositsStore.confirmP2pTransfer();
  };

  render(): ReactNode {
    const {transferred, remainingTime} = this.state;

    const {t} = this.props as WithTranslation;
    const {
      current_p2p_deposit:
            {
              // Add grouped_currency_amount
              id, currency, amount,
              wallet: {bank, account_decrypt},
              verified_document,
              system, cashout, state
            },
      submitting,
      currentPaymentSystem: {related_to_bank_bins, payment_by_screenshot}
    } = this.p2pDepositsStore;
    const disabled = !transferred || submitting || this.stableStatuses;
    const withTimerAndPromo = !this.stableStatuses;
    const transferMade = transferred || this.stableStatuses;
    const receiverAccount = translatedKeyLangs(system, 'account_labels') || t('receiver_account');
    const comment = translatedKeyLangs(system, 'deposit_comments') || t('no_comment');
    const description = translatedKeyLangs(system, 'deposit_descriptions');
    return <Fragment>
      {!this.stableStatuses && <div className="ofelos-pay-container-text">
        {description
          ? <p>{description}</p>
          : <>
            <p>{t('confirm_payment')}</p>
            <p>{t('have_15min')}</p>
          </>}
      </div>}
      {withTimerAndPromo && <div className="ofelos-pay-timer-deposit">
        {t('you_have')}
        <span className="timer">
          <SvgSprite sprite_name="svg-timer-icon"/>
          <b>{remainingTime.minutes} {t('min_short')}</b>
          <b>{remainingTime.seconds} {t('sec_short')}</b>
        </span>
        {t('to_make_transfer')}
      </div>}
      <div className={`ofelos-pay-card-transfer${payment_by_screenshot ? ' card-screenshot-info' : ''}`}>
        {related_to_bank_bins && <div className="bank-logotype">
          <span className="logotype">
            {bank
              ? <img src={bank.logo.url} title={bank.name} alt={bank.name}/>
              : <img src={bankLogotypeSvg} title="bankLogo" alt="bankLogo"/>
            }
          </span>
        </div>}
        <div className="ofelos-pay-columns">
          <div className="ofelos-pay-column">
            <span className="title">{t('amount')}:</span>
            <span className="total">
              {amountFormat(amount)}
                            &nbsp;
              {isoCurrencyToHtml(currency)}
            </span>
            <CopyButton value={amountFormat(amount)}/>
          </div>
          {!payment_by_screenshot && <div className="ofelos-pay-column">
            <span className="title">{receiverAccount}:</span>
            <span className="total">{account_decrypt}</span>
            <CopyButton value={account_decrypt}/>
          </div>}
          {!payment_by_screenshot && <div className="ofelos-pay-column">
            <span className="title">{t('payment_comment')}:</span>
            <span className="total">{comment}</span>
            <CopyButton value={comment}/>
          </div>}
        </div>
        {
          payment_by_screenshot && <ScreenshotPayment screenshot_url={cashout.file && cashout.file.url}/>
        }
        <ScreenshotLoader
          id={id}
          file={verified_document && verified_document.file}
          store={this.p2pDepositsStore}
          state={state}
        />
      </div>
      {this.stableStatuses
        ? <StatusBar state={state} verified={verified_document && Boolean(verified_document.file)}/>
        : <div className="ofelos-pay-confirm-transfer">
          <Checkbox
            label={t('transfer_made')}
            onChange={this.transferred}
            value={1}
            checked={transferMade}
            id="transferMadeId"
          />
          <button
            className={`ofelos-pay-button${disabled ? ' disabled' : ''}`}
            disabled={disabled}
            onClick={this.confirmTransfer}
          >
            {t('confirm_transfer')}
          </button>
        </div>
      }
    </Fragment>;
  }
}

export default withTranslation()(CurrentDeposit);


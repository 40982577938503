import round from 'lodash/round';

const PRECISION = 2;

const amountFormat = (amount: number, isInteger?: boolean): string => {
  const precision = isInteger ? 0 : PRECISION;
  const roundedAmount = round(Number(amount), precision).toFixed(precision);

  if (isNaN(amount)) {
    return '0';
  }
  return roundedAmount.replace(/(?<v1>\d)(?=(?<v2>\d{3})+(?!\d))/ug, '$1,');
};

export default amountFormat;

import React, {memo, ReactElement} from 'react';
import SvgSprite from 'utils/svgSprite/svgSprite';
import UploadDocumentButton from 'components/UploadDocumentButton/UploadDocumentButton';
import ImageType from 'frontend_module/src/interfaces/ImageType';
import {P2pCashoutsStore} from 'stores/P2pCashoutsStore';
import {P2pDepositsStore} from 'stores/P2pDepositsStore';
import {useTranslation} from 'react-i18next';

interface ScreenshotLoaderProps {
    id: string
    cashout?: boolean
    file: ImageType
    store: P2pCashoutsStore | P2pDepositsStore
    state: string
}

const ScreenshotLoader = memo((props: ScreenshotLoaderProps): ReactElement => {
  const {t} = useTranslation();

  const {file, cashout, store, id} = props;
  const finalStatuses = (): boolean => [
    'cancelled',
    'completed',
    'denied'
  ].includes(props.state);
  const disabledLoaderButton = Boolean(file && file.url) || finalStatuses();


  return <div className="ofelos-pay-loader-screenshot">
    { cashout
      ? <div className="ofelos-pay-loader-screenshot-description">
        <SvgSprite sprite_name="svg-qr-code" width="17px" height="17px" />
        <span>{t('qr_code_payment')}</span>
      </div>
      : <div className="ofelos-pay-loader-screenshot-description">
        <span className="icon">
          {disabledLoaderButton
            ? <SvgSprite sprite_name="svg-document-done" />
            : <SvgSprite sprite_name="svg-document-timer" />}
        </span>
        <div className="description">
          {disabledLoaderButton
            ? <p>{t('your_screenshot_uploaded_successfully')}</p>
            : <p>{t('you_can_speed_up_the_process')}</p>}
        </div>
      </div>
    }
    <div className="ofelos-pay-loader-screenshot-button">
      <UploadDocumentButton
        secure_id={id}
        maxDocumentsLoaded={disabledLoaderButton}
        cashout={cashout}
        store={store}
        t={t}
      />
    </div>
  </div>;
});

export default ScreenshotLoader;

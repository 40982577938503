import React from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastContent from 'components/Toasts/Contents/ToastContent';
import SvgSprite from 'utils/svgSprite/svgSprite';

const toastError = (message: string): React.ReactText => toast.error(<ToastContent
  content={message}>
  <SvgSprite sprite_name={'svg-toast-error'}/>
</ToastContent>, {
  icon: false
});

const toastSuccess = (message: string): React.ReactText => toast.success(<ToastContent
  content={message}>
  <SvgSprite sprite_name={'svg-toast-done'}/>
</ToastContent>, {
  icon: false
});

const toastInfo = (message: string): React.ReactText => toast.info(<ToastContent
  content={message}>
  <SvgSprite sprite_name={'svg-toast-info'}/>
</ToastContent>, {
  icon: false
});

const toastWarning = (message: string): React.ReactText => toast.warning(<ToastContent
  content={message}>
  <SvgSprite sprite_name={'svg-toast-info'}/>
</ToastContent>, {
  icon: false
});

export {toastError, toastSuccess, toastInfo, toastWarning};

// Const ASYMP_EQUAL = 8776;
const BTC = 579;
const CNY = 165;
const EUR = 8364;
const INR = 8377;
const KRW = 8361;
const KZT = 8376;
const MXN = 8369;
const PHP = 8369;
const RUB = 8381;
const THB = 3647;
const UAH = 8372;

export const ISO_CURRENCY_HTML = {
  BRL: 'R$',
  BTC: String.fromCharCode(BTC),
  BYN: 'Br',
  CNY: String.fromCharCode(CNY),
  EUR: String.fromCharCode(EUR),
  IDR: 'Rp',
  INR: String.fromCharCode(INR),
  KRW: String.fromCharCode(KRW),
  KZT: String.fromCharCode(KZT),
  MXN: String.fromCharCode(MXN),
  MYR: 'RM',
  PHP: String.fromCharCode(PHP),
  RUB: String.fromCharCode(RUB),
  THB: String.fromCharCode(THB),
  UAH: String.fromCharCode(UAH),
  USD: '$'
};

const isoCurrencyToHtml = (currency: keyof typeof ISO_CURRENCY_HTML): string => {
  const res = ISO_CURRENCY_HTML[currency];
  return res || currency;
};

export default isoCurrencyToHtml;

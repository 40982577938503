import React, {ReactNode, PureComponent} from 'react';
import SystemType from 'interfaces/SystemType';

interface SystemCardProps {
    system: SystemType
}

export default class SystemCard extends PureComponent<SystemCardProps> {
  render(): ReactNode {
    const {system, system: {logo}} = this.props;
    return <>
      {logo?.url && <img
        src={logo.url}
        alt={system.name}
        title={system.name}
      />}
    </>;
  }
}

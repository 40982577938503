import React, {ReactElement, useCallback} from 'react';
import SvgSprite from 'utils/svgSprite/svgSprite';
import {useTranslation} from 'react-i18next';

export const MenuLang = (): ReactElement => {
  const {t, i18n} = useTranslation();

  const changeLngRu = useCallback(() => {
    i18n.changeLanguage('ru');
  }, []);

  const changeLngEn = useCallback(() => {
    i18n.changeLanguage('en');
  }, []);


  return <nav className="ofelos-pay-lang">
    <div className="ofelos-pay-lang-current">
      <span className="ofelos-pay-lang-country-icon">
        <SvgSprite sprite_name={`svg-${i18n.language}-icon`}/>
      </span>
      <span className="ofelos-pay-menu-arrow-icon">
        <SvgSprite sprite_name="svg-menu-icon"/>
      </span>
    </div>
    <ul className="ofelos-pay-menu-lang">
      <li>
        <a className="ofelos-pay-menu-lang-item" onClick={changeLngRu}>
          <span className="ofelos-pay-lang-name">{t('russian')}</span>
          <span className="ofelos-pay-lang-country-icon">
            <SvgSprite sprite_name="svg-ru-icon"/>
          </span>
        </a>
      </li>
      <li>
        <a className="ofelos-pay-menu-lang-item" onClick={changeLngEn}>
          <span className="ofelos-pay-lang-name">{t('english')}</span>
          <span className="ofelos-pay-lang-country-icon">
            <SvgSprite sprite_name="svg-en-icon"/>
          </span>
        </a>
      </li>
    </ul>
  </nav>;
};

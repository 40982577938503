import React, {Component, ReactElement} from 'react';
import {t} from 'i18next';

interface SubmitButtonPropsInterface {
    cancelClick?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void
    cancelText?: string
    submitting: boolean
}

export class CancelButton extends Component<SubmitButtonPropsInterface> {
  disabledClass(): string {
    return this.props.submitting ? ' button-adaptive_disable' : '';
  }

  render(): ReactElement {
    return <button type="button"
      className={`ofelos-pay-button cancel${this.disabledClass()}`}
      disabled={this.props.submitting}
      onClick={this.props.cancelClick}>
      {this.props.cancelText || t('confirm_button_no')}
    </button>;
  }
}

import {action, observable} from 'mobx';
import moment from 'moment';

const UPDATE_TIME = 100;

export class TimeStore {
  constructor(time?: number) {
    this.time = time ? time : moment().unix();
    setInterval(() => this.setTime(this.time + 1), UPDATE_TIME);
  }

  @observable time: number;

  @action setTime(time: number): void {
    this.time = time;
  }
}

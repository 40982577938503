import React, {memo, ReactElement} from 'react';
import SupportButton from 'components/SupportButton/SupportButton';
import SvgSprite from 'utils/svgSprite/svgSprite';
import {useTranslation} from 'react-i18next';

const HelpSupport = memo((): ReactElement => {
  const {t} = useTranslation();

  return <div className="ofelos-pay-support">
    <span className="icon">
      <SvgSprite sprite_name="svg-question-icon"/>
    </span>
    <div className="description">
      <p>{t('failed_to_make_a_transfer')}</p>
      <p>{t('contact_to')} <SupportButton hideIcon/>. {t('we_can_find_a_quick_solution')}</p>
    </div>
  </div>;
});

export default HelpSupport;

import React, {ReactElement} from 'react';
import SvgSpriteAsImage from 'utils/svgSprite/svgSpriteAsImage';
import {MenuLang} from 'components/MenuLang/MenuLang';

const Header = (): ReactElement => <header className="ofelos-pay-header">
  <div className="ofelos-pay-inner">
    <div className="ofelos-pay-logotype">
      <SvgSpriteAsImage sprite_name="svg-offelos-pay-logotype" alt="Ofelos Pay Logotype"/>
    </div>
    <MenuLang/>
  </div>
</header>;

export default Header;

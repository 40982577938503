import React, {memo, ReactElement} from 'react';
import SupportButton from 'components/SupportButton/SupportButton';
import VerifiedDocument from 'interfaces/VerifiedDocument';
import SvgSprite from 'utils/svgSprite/svgSprite';
import {useTranslation} from 'react-i18next';

interface CurrentP2pDepositProps {
    user_verified_document: VerifiedDocument
    state: string
}

const STEP_1 = 1;
const STEP_2 = 2;
const STEP_3 = 3;

const P2pDepositSteps = memo((props: CurrentP2pDepositProps): ReactElement => {
  const {t} = useTranslation();

  const cancelled = (): boolean => ['denied', 'cancelled'].includes(props.state);

  const doneStep1 = (): boolean => !['working'].includes(props.state) && !cancelled();

  const activeStep2 = (): boolean => [
    'waiting',
    'suspended',
    'not_valid'
  ].includes(props.state);

  const transactionDone = (): boolean => ![
    'waiting',
    'not_valid',
    'working',
    'suspended'
  ].includes(
    props.state
  ) && !cancelled();
  const disabledLoaderButton = Boolean(props.user_verified_document);


  return <div className="ofelos-pay-steps">
    <div className="ofelos-pay-step">
      <div className="body">
        {doneStep1()
          ? <span className="number">
            <SvgSprite sprite_name="svg-check-icon" />
          </span>
          : <span className={`number ${cancelled() ? '' : 'active'}`}>{STEP_1}</span>}
        <div className="title">
          <p>{doneStep1() ? t('deposit_step_1') : t('deposit_step_1_start')}</p>
        </div>
        {!doneStep1() && <div className="description">
          <p>{t('deposit_step_start')}</p>
        </div>}
      </div>
    </div>
    <div className="ofelos-pay-step">
      <div className="body">
        {transactionDone()
          ? <span className="number">
            <SvgSprite sprite_name="svg-check-icon" />
          </span>
          : <span className={`number${activeStep2() ? ' active' : ''}`}>{STEP_2}</span>}
        <div className="title">
          <p>{t('deposit_step_2')}</p>
        </div>
        <div className="ofelos-pay-step-screenshot">
          <span className={`icon${disabledLoaderButton ? ' active' : ''}`}>
            {disabledLoaderButton
              ? <SvgSprite sprite_name="svg-document-done" />
              : <SvgSprite sprite_name="svg-document-timer" />}
          </span>
          <div className="text">
            <p>{t('deposit_step_screenshot')}</p>
          </div>
        </div>
        <div className="ofelos-pay-step-support">
          <span className="icon">
            <SvgSprite sprite_name={'svg-icon-chat'} />
          </span>
          <div className="text">
            <p>{t('deposit_step_write_support_1')}
              <SupportButton hideIcon/>
              {t('deposit_step_write_support_2')}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="ofelos-pay-step">
      <div className="body">
        {transactionDone()
          ? <span className="number">
            <SvgSprite sprite_name="svg-check-icon" />
          </span>
          : <span className="number">{STEP_3}</span>}
        <div className="title">
          <p>{t('deposit_step_3')}</p>
        </div>
      </div>
    </div>
  </div>;
});

export default P2pDepositSteps;

import moment from 'moment';

const FIFTEEN_MINUTES = 15;

const remainingTimeUntil15Minutes = (date: string): boolean => {
  const future = moment(date).add(FIFTEEN_MINUTES, 'minutes');
  const now = moment();
  return Boolean(future.diff(now, 'seconds') > 0);
};

export default remainingTimeUntil15Minutes;

import React, {memo, ReactElement} from 'react';
import SvgSprite from 'utils/svgSprite/svgSprite';
import {useTranslation} from 'react-i18next';

interface SupportButtonProps {
    hideIcon?: boolean
}

const SupportButton = memo((props: SupportButtonProps): ReactElement => {
  const {t} = useTranslation();

  const {hideIcon} = props;
  return <a
    href="mailto:support@ofelospay.com"
    target="_blank"
    rel="noreferrer"
    className={hideIcon ? '' : 'support__link support__disabled'}
  >
    {!hideIcon && <SvgSprite sprite_name={'svg-question'} />}
    {t('support')}
  </a>;
});

export default SupportButton;

import moment from 'moment';

const SECONDS_IN_MINUTES = 60;

const upToMinutes = (date: string, MINUTES: number): {seconds: number, minutes: number} => {
  const future = moment(date).add(MINUTES, 'minutes');
  const now = moment();
  const minutes = future.diff(now, 'minutes');
  return {
    minutes,
    seconds: future.diff(now, 'seconds') - SECONDS_IN_MINUTES * minutes
  };
};

export default upToMinutes;

import React, {Component, Fragment, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import bankLogotypeSvg from 'images/p2p/bank-logotype.svg';
import ScreenshotLoader from 'components/ScreenshotLoader/ScreenshotLoader';
import {t} from 'i18next';
import P2pCashoutsStoreProps from 'interfaces/P2pCashoutsStoreProps';
import {P2pCashoutsStore} from 'stores/P2pCashoutsStore';

@inject('p2pCashoutsStore')
@observer
export default class CurrentScreenshotCashout extends Component {
  get p2pCashoutsStore(): P2pCashoutsStore {
    const {p2pCashoutsStore} = this.props as P2pCashoutsStoreProps;
    return p2pCashoutsStore;
  }

  render(): ReactNode {
    const {
      current_p2p_cashout:
            {id, wallet: {bank}, file, state},
      currentPaymentSystem: {related_to_bank_bins, payment_by_screenshot}
    } = this.p2pCashoutsStore;

    return <Fragment>
      <div className={`card-transfer-info form-egb ${payment_by_screenshot && 'card-screenshot-info'}`}>
        {related_to_bank_bins && <div className="bank-logotype">
          <span className="logotype">
            {bank
              ? <img src={bank.logo.url} title={bank.name} alt={bank.name}/>
              : <img src={bankLogotypeSvg} title="bankLogo" alt="bankLogo"/>
            }
          </span>
        </div>}
        <div className="rows rows__info">
          <p>{t('cashout_screenshot_title')}</p>
        </div>
        <ScreenshotLoader
          id={id}
          file={file}
          cashout
          store={this.p2pCashoutsStore}
          state={state}
        />
      </div>
    </Fragment>;
  }
}

import {ReactElement} from 'react';
import ReactDOM from 'react-dom';

const showPopup = (elementId: string, component: ReactElement): void => {
  const element = document.createElement('div');
  element.id = elementId;
  document.body.prepend(element);
  ReactDOM.render(component, element);
};

export default showPopup;

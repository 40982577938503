import React, {memo} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import SvgSprite from 'utils/svgSprite/svgSprite';
import {useTranslation} from 'react-i18next';

interface CopyButtonProps {
    value: string
}

const CopyButton = memo(({value}: CopyButtonProps) => {
  const {t} = useTranslation();

  return <div className="ofelos-pay-copy-button">
    <SvgSprite sprite_name="svg-copy-icon"/>
    <CopyToClipboard text={value}>
      <span className="name">{t('copy')}</span>
    </CopyToClipboard>
  </div>;
});

export default CopyButton;

import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

const Footer = (): ReactElement => {
  const {t} = useTranslation();

  return <footer className="ofelos-pay-footer">
    <div className="ofelos-pay-inner">
      <div className="ofelos-pay-copyright">
        <span>{t('short_name')} © {(new Date().getFullYear())}</span>
      </div>
    </div>
  </footer>;
};

export default Footer;

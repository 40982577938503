import React, {PureComponent} from 'react';

export interface ToastContentProps {
    closeToast?: () => void
    children?: React.ReactNode | React.ReactText
    content?: string | React.ReactNode | React.ReactText
}

export default class ToastContent<P> extends PureComponent<P & ToastContentProps> {
  render(): React.ReactNode {
    const {children, content} = this.props;
    return <div className="Toastify__notification">
      <div className="Toastify__notification__icon">
        {children}
      </div>
      <div className="Toastify__notification__content">
        {typeof content === 'string'
          ? <div
            className="Toastify__notification__content--text"
            dangerouslySetInnerHTML={{__html: content}}
          />
          : <div className="Toastify__notification__content--text">
            {content}
          </div>}
      </div>
    </div>;
  }
}

import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {Provider} from 'mobx-react';
import Get from 'frontend_module/src/utils/fetch/Get';
import apiUrl from 'utils/apiUrl/apiUrl';
import P2pDepositType from 'interfaces/P2pDepositType';
import {P2pDepositsStore} from 'stores/P2pDepositsStore';
import CurrentP2pDeposit from 'components/CurrentP2pDeposit/CurrentP2pDeposit';
import {TimeStore} from 'stores/TimeStore';
import {useParams} from 'react-router-dom';

type StoresType = {
    p2pDepositsStore?: P2pDepositsStore,
    timeStore: TimeStore;
};

const Deposits = (): ReactElement => {
  const p2pDepositsStore = useRef<P2pDepositsStore>();
  const [loaded, setLoading] = useState(false);
  const stores: StoresType = {
    p2pDepositsStore: p2pDepositsStore.current,
    timeStore: new TimeStore()
  };
  const params = useParams<{id: string}>();

  useEffect(() => {
    if (p2pDepositsStore.current) {
      setLoading(true);
      return;
    }
    new Get({url: apiUrl(`/v1/deposits/${params.id}`)}).execute()
      .then(response => response.ok && response.json())
      .then((result: P2pDepositType) => {
        p2pDepositsStore.current = new P2pDepositsStore(result);
        setLoading(true);
      });
  });

  return loaded
    ? <Provider {...stores}>
      <CurrentP2pDeposit/>
    </Provider>
    : <></>;
};

export default Deposits;

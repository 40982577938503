import React from 'react';
import {toast, ToastContainer, Zoom} from 'react-toastify';
import {CloseButton} from 'components/Toasts/CloseButton';

const AUTO_CLOSE_TIME_TOAST = 8000;

interface ToastContainerCustomizeProps {
    className?: string
}

export const ToastContainerCustomize = (props: ToastContainerCustomizeProps): React.ReactElement => <ToastContainer
  position={toast.POSITION.BOTTOM_RIGHT}
  className={props.className}
  autoClose={AUTO_CLOSE_TIME_TOAST}
  transition={Zoom}
  hideProgressBar
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss={false}
  draggable
  pauseOnHover
  closeButton={<CloseButton/>}
/>;

import React, {ReactElement} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Deposits from 'pages/Deposits/Deposits';
import Cashouts from 'pages/Cashouts/Cashouts';
import DepositNotFound from 'pages/DepositNotFound/DepositNotFound';

const AppRoutes = (): ReactElement => <Router>
  <Routes>
    <Route path="/deposits" element={<DepositNotFound />}/>
    <Route path="/deposits/:id" element={<Deposits />}/>
    <Route path="/cashouts/:id" element={<Cashouts />}/>
  </Routes>
</Router>;

export default AppRoutes;

import React, {PureComponent, ReactElement} from 'react';
import {t} from 'i18next';
import {SubmitButton} from 'components/ModalComponents/SubmitButton/SubmitButton';
import SvgSprite from 'utils/svgSprite/svgSprite';

interface ModalConfirmPropsInterface {
    id: string
    deleteModal(): void
    okClick(): void
    cancelClick?(): void
    children: React.ReactNode
    okText?: string
    cancelText?: string
}

export class ModalConfirm extends PureComponent<ModalConfirmPropsInterface> {
  cancelClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e && e.preventDefault();
    this.props.cancelClick && this.props.cancelClick();
    this.close();
  };

  close = (): void => {
    this.props.deleteModal();
  };

  render(): ReactElement | null {
    const {okClick, okText, cancelText} = this.props;

    return <div id="popup" className="ofelos-pay-popup">
      <div className="ofelos-pay-popup-container">
        <span className="ofelos-pay-popup-button-close" onClick={this.close}>
          <SvgSprite sprite_name={'svg-close-icon'}/>
        </span>
        <div className="ofelos-pay-popup-description">
          <p>{this.props.children}</p>
        </div>
        <SubmitButton
          className="ofelos-pay-popup-buttons"
          okClick={okClick}
          cancelClick={this.cancelClick}
          title={okText || t('confirm_button_yes')}
          cancelText={cancelText}/>
      </div>
    </div>;
  }
}

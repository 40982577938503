import React, {PureComponent, ReactNode} from 'react';
import {ModalConfirm} from 'components/ModalComponents/ModalConfirm';
import {AddConfirmModalInterface} from 'components/ModalComponents/ModalContainer/AddConfirmModalInterface';
import {inject, observer} from 'mobx-react';
import {ModalsControlStore} from 'stores/ModalsControlStore';
import ModalsControlStoreProps from 'interfaces/ModalsControlStoreProps';

interface ModalContainerState {
    modal: ModalConfirm | null
}

@inject('modalsControlStore')
@observer
export class ModalContainer extends PureComponent {
  state: ModalContainerState = {
    modal: null
  };

  get modalsControlStore(): ModalsControlStore {
    const {modalsControlStore} = this.props as ModalsControlStoreProps;
    return modalsControlStore;
  }

  componentDidMount(): void {
    this.modalsControlStore.setModal(this.addModalConfirm);
    this.modalsControlStore.setCloseModal(this.deleteModal);
  }

  deleteModal = (): void => {
    this.setState({modal: null});
  };

  addModalConfirm = ({text, onOkClick, onCancel}: AddConfirmModalInterface): void => {
    this.setState({modal: <ModalConfirm
      id="modal_confirm"
      key="modal_confirm"
      okClick={onOkClick}
      cancelClick={onCancel && onCancel.click}
      cancelText={onCancel && onCancel.text}
      deleteModal={this.deleteModal}>
      {text}
    </ModalConfirm>});
  };

  render(): ReactNode {
    return this.state.modal;
  }
}

import React, {ReactElement, useCallback} from 'react';
import SvgSprite from 'utils/svgSprite/svgSprite';
import {useTranslation} from 'react-i18next';
import {P2pDepositsStore} from 'stores/P2pDepositsStore';
import amountFormat from 'utils/amountFormat/amountFormat';
import {ModalsControlStore} from 'stores/ModalsControlStore';
import {P2pCashoutsStore} from 'stores/P2pCashoutsStore';

interface BreadcrumbInterface {
    p2pCashoutsStore?: P2pCashoutsStore
    p2pDepositsStore?: P2pDepositsStore
    modalsControlStore?: ModalsControlStore
}

const Breadcrumb = ({p2pDepositsStore, modalsControlStore, p2pCashoutsStore}: BreadcrumbInterface): ReactElement => {
  const {t} = useTranslation();

  const redirectWithoutModal = (): void => {
    if (p2pDepositsStore && p2pDepositsStore.current_p2p_deposit.state !== 'working') {
      redirect(p2pDepositsStore.current_p2p_deposit.back_url);
    }

    if (p2pCashoutsStore) {
      redirect(p2pCashoutsStore.current_p2p_cashout.back_url);
    }
  };

  const redirect = (url: string) => {
    window.location.replace(url);
  };

  const callback = () => {
    if (!p2pDepositsStore || !modalsControlStore) {
      return;
    }

    const {current_p2p_deposit: {back_url}} = p2pDepositsStore;

    const {closeModal} = modalsControlStore;

    closeModal && closeModal();
    redirect(back_url);
  };

  const leave = useCallback((): void => {
    redirectWithoutModal();

    if (!p2pDepositsStore || !modalsControlStore) {
      return;
    }

    const {modalConfirm} = modalsControlStore;

    const {current_p2p_deposit: {id, amount, state}} = p2pDepositsStore;

    if (state !== 'working') {
      return;
    }

    const params = {
      onCancel: {text: t('confirm_button_no_сontinue')},
      onOkClick: () => p2pDepositsStore.cancelP2pDeposit(id, callback),
      text: t('undo_deposit', {amount: amountFormat(amount)})
    };

    modalConfirm && modalConfirm(params);
  }, []);

  return <div className="ofelos-pay-breadcrumb">
    <a onClick={leave}
      className="ofelos-pay-breadcrumb-item">
      <span className="icon">
        <SvgSprite sprite_name="svg-back-icon"/>
      </span>
      <span className="name">{t('back')}</span>
    </a>
  </div>;
};

export default Breadcrumb;

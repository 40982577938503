import {action, makeAutoObservable, observable} from 'mobx';
import P2pDepositType from 'interfaces/P2pDepositType';
import Currencies from 'utils/currencies/currencies';
import Post from 'frontend_module/src/utils/fetch/Post';
import BankType from 'interfaces/BankType';
import SystemType from 'interfaces/SystemType';
import Get from 'frontend_module/src/utils/fetch/Get';
import apiUrl from 'utils/apiUrl/apiUrl';
import {toastError, toastSuccess} from 'components/Toasts/liteToast';
import {t} from 'i18next';
import {OK_STATUS} from 'utils/const/const';

export class P2pDepositsStore {
    @observable current_p2p_deposit: P2pDepositType;

    @observable submitting = false;

    @observable currentBank: BankType;

    @observable currentPaymentSystem: SystemType;

    @observable currency: keyof typeof Currencies = 'USD';

    constructor(deposit: P2pDepositType) {
      makeAutoObservable(this);
      this.current_p2p_deposit = deposit;
      this.currentPaymentSystem = deposit.system;
      this.currentBank = deposit.wallet.bank;
    }

    @action update = (deposit: P2pDepositType): void => {
      this.current_p2p_deposit = deposit;
      this.currentPaymentSystem = deposit.system;
      this.currentBank = deposit.wallet.bank;
    };

    @action updateSubmitting = (value: boolean): void => {
      this.submitting = value;
    };

    acceptP2pDeposit = (deposit: P2pDepositType): Promise<void> => {
      const {id} = this.currentPaymentSystem;
      return new Post({
        params: {
          cashout_id: deposit.cashout,
          id: deposit.id,
          payment_system_id: id
        },
        url: apiUrl('/v1/deposits/p2p_deposits')
      })
        .execute()
        .then(data => data.json())
        .then(data => {
          if (data.message) {
            toastError(data.message);
          } else {
            this.update(data.start_result.current_p2p_deposit);
          }
        });
    };

    startDepositByAmount = (amount: string): Promise<unknown> => {
      const {id} = this.currentPaymentSystem;
      return new Post({
        params: {
          amount: amount || 0,
          payment_system_id: id
        },
        url: apiUrl('/v1/deposits/p2p_deposits')
      })
        .execute()
        .then(data => data.json())
        .then(data => {
          if (data.message) {
            toastError(data.message);
          } else {
            this.update(data.start_result.current_p2p_deposit);
          }
        });
    };

    confirmP2pTransfer = (close_modal?: () => void): void => {
      const {id} = this.current_p2p_deposit;
      this.updateSubmitting(true);
      new Post({
        url: apiUrl(`/v1/deposits/p2p_deposits/${id}/confirm`)
      })
        .execute()
        .then(data => data.json())
        .then(data => {
          this.updateSubmitting(false);
          if (data.error) {
            toastError(t('server_error_try_later'));
          } else {
            this.loadCurrentP2pDeposits();
            toastSuccess(t('deposit_step_1'));
          }
          close_modal && close_modal();
        });
    };

    loadCurrentP2pDeposits = (): void => {
      const {id} = this.current_p2p_deposit;
      new Get({url: apiUrl(`/v1/deposits/${id}`)})
        .execute()
        .then(data => data.json())
        .then(data => this.update(data));
    };

    cancelP2pDeposit = (id: string, callback?: () => void): Promise<void | Response> => new Post({
      url: apiUrl(`/v1/deposits/${id || this.current_p2p_deposit.id}/cancel`)
    }).execute()
      .then(response => {
        if (response.status === OK_STATUS) {
          this.loadCurrentP2pDeposits();
          toastSuccess(t('deposit_cancelled'));
        } else {
          toastError(t('server_error_try_later'));
        }
        callback && callback();
      });


    sendDocuments = (formData: () => FormData, afterSave?: () => void): void => {
      fetch(apiUrl('/v1/verified_documents'), {
        body: formData(),
        method: 'POST'
      })
        .then(response => {
          if (response.status === OK_STATUS) {
            afterSave && afterSave();
            toastSuccess(t('successfully_uploaded'));
          } else {
            toastError(t('uploaded_file_too_large'));
          }
          this.loadCurrentP2pDeposits();
        });
    };
}

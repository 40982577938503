import React, {ChangeEvent, FormEvent, MouseEvent, PureComponent, ReactNode} from 'react';
import SmartLoader from 'components/reusable/SmartLoader';
import {P2pCashoutsStore} from 'stores/P2pCashoutsStore';
import {P2pDepositsStore} from 'stores/P2pDepositsStore';
import {TFunction} from 'react-i18next';

interface UploadButtonProps {
    secure_id: string
    maxDocumentsLoaded: boolean
    cashout?: boolean
    store: P2pCashoutsStore | P2pDepositsStore
    t: TFunction
}

interface UploadButtonState {
    document: File | string
    filename: string
    loaded: boolean
}

class UploadDocumentButton extends PureComponent<UploadButtonProps, UploadButtonState> {
  constructor(props: UploadButtonProps) {
    super(props);
    this.state = {
      document: '',
      filename: '',
      loaded: false
    };
  }

  fileRef = React.createRef<HTMLInputElement>();

  submitHandler = (e: FormEvent<HTMLFormElement> | null): void => {
    e && e.preventDefault();
    this.setState({loaded: true});

    this.props.store.sendDocuments(this.formData, this.afterSave);
  };

  afterSave = (): void => {
    this.disableLoading();
    this.setState({
      document: '',
      filename: ''
    });
  };

  documentUploadHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files === null) {
      return;
    }

    const file = e.target.files.item(0);

    if (!file) {
      return;
    }

    this.setState({
      document: file,
      filename: file.name
    }, () => {
      this.submitHandler(null);
    });
  };

  formData = (): FormData => {
    const {document} = this.state;
    const {cashout, secure_id} = this.props;
    const formData = new FormData();

    if (cashout) {
      formData.append('cashout[file]', document);
    } else {
      formData.append('user_verified_document[file]', document);
      formData.append('user_verified_document[secure_id]', `${secure_id}`);
    }
    return formData;
  };

  disableLoading = (): void => this.setState({loaded: false});

  chooseFile = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    if (this.fileRef.current) {
      this.fileRef.current.value = '';
      this.fileRef.current.click();
    }
  };

  render(): ReactNode {
    const {loaded} = this.state;
    const {maxDocumentsLoaded, t} = this.props;
    return <>
      {loaded && <SmartLoader/>}
      <input
        className="ofelos-pay-input-file"
        ref={this.fileRef}
        type="file"
        disabled={maxDocumentsLoaded}
        onChange={this.documentUploadHandler}
      />

      {!loaded && <button
        onClick={this.chooseFile}
        className={`ofelos-pay-button-upload${maxDocumentsLoaded ? ' disabled' : ''}`}
      >
        {t('upload_button')}
      </button>}
    </>;
  }
}

export default UploadDocumentButton;

import React, {ReactElement} from 'react';
import 'App.scss';
import AppRoutes from 'pages/AppRouters/AppRoutes';
import {ModalContainer} from 'components/ModalComponents/ModalContainer/ModalContainer';
import {ModalsControlStore} from 'stores/ModalsControlStore';
import {Provider} from 'mobx-react';
import {ToastContainerCustomize} from 'components/Toasts/ToastContainerCustomize';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

type StoresType = {
    modalsControlStore: ModalsControlStore
};

process.env.REACT_APP_BRANCH && console.log(`Branch name = ${process.env.REACT_APP_BRANCH}`);

const App = (): ReactElement => {
  const stores: StoresType = {
    modalsControlStore: new ModalsControlStore()
  };
  return <Provider {...stores}>
    <Header/>
    <section className="ofelos-pay-section">
      <div className="ofelos-pay-inner">
        <AppRoutes/>
      </div>
    </section>
    <Footer/>
    <ModalContainer/>
    <ToastContainerCustomize/>
  </Provider>;
};

export default App;

import React, {Component, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import isoCurrencyToHtml from 'utils/currencyIsoFormat/isoCurrencyToHtml';
import bankLogotypeSvg from 'images/p2p/bank-logotype.svg';
import amountFormat from 'utils/amountFormat/amountFormat';
import {translatedKeyLangs} from 'utils/translatedKeyLangs/transaltedKeyLangs';
import P2pCashoutsStoreProps from 'interfaces/P2pCashoutsStoreProps';
import {P2pCashoutsStore} from 'stores/P2pCashoutsStore';
import {StatusBar} from 'components/CurrentCashout/StatusBar/StatusBar';
import {ChangeStatusButtons} from 'components/CurrentCashout/ChangeStatusButtons/ChangeStatusButtons';
import {WithTranslation, withTranslation} from 'react-i18next';

interface CurrentCashoutState {
    modal?: boolean
}

@inject('p2pCashoutsStore')
@observer
class CurrentCashout extends Component {
  state: CurrentCashoutState = {};

  get p2pCashoutsStore(): P2pCashoutsStore {
    const {p2pCashoutsStore} = this.props as P2pCashoutsStoreProps;
    return p2pCashoutsStore;
  }

  get waitingOrNotValid(): boolean {
    return ['waiting', 'not_valid'].includes(
      this.p2pCashoutsStore.current_p2p_cashout.state
    );
  }

  render(): ReactNode {
    const {t} = this.props as WithTranslation;
    const {
      current_p2p_cashout:
            {
              currency, amount, system, state,
              wallet: {bank, account}
            },
      currentPaymentSystem: {related_to_bank_bins, payment_by_screenshot}
    } = this.p2pCashoutsStore;
    const receiverAccount = translatedKeyLangs(system, 'account_labels') || t('your_account');
    const comment = translatedKeyLangs(system, 'deposit_comments') || t('no_comment');
    return <>
      <div className={`ofelos-pay-card-transfer${payment_by_screenshot ? ' card-screenshot-info' : ''}`}>
        {related_to_bank_bins && <div className="bank-logotype">
          <span className="logotype">
            {bank
              ? <img src={bank.logo.url} title={bank.name} alt={bank.name}/>
              : <img src={bankLogotypeSvg} title="bankLogo" alt="bankLogo"/>
            }
          </span>
        </div>}
        <div className="ofelos-pay-columns">
          <div className="ofelos-pay-column">
            <span className="title">{t('amount')}:</span>
            <span className="total">
              {amountFormat(amount)}
                            &nbsp;
              {isoCurrencyToHtml(currency)}
            </span>
          </div>
          <div className="ofelos-pay-column">
            <span className="title">{receiverAccount}:</span>
            <span className="total">{account}</span>
          </div>
          <div className="ofelos-pay-column">
            <span className="title">{t('payment_comment')}:</span>
            <span className="total">{comment}</span>
          </div>
        </div>
        <StatusBar cashout state={state} className="box"/>
      </div>
      {this.waitingOrNotValid &&
        <ChangeStatusButtons p2pCashoutsStore={this.p2pCashoutsStore}/> }
    </>;
  }
}

export default withTranslation()(CurrentCashout);

import React, {PureComponent, ReactNode} from 'react';
import showPopup from 'utils/showPopup/showPopup';
import PopupDialog from 'components/PopupDialog/PopupDialog';
import isDesktop from 'frontend_module/src/utils/isDesktop';
import {t} from 'i18next';

interface ScreenshotPaymentProps {
    screenshot_url: string
}

export default class ScreenshotPayment extends PureComponent<ScreenshotPaymentProps> {
  enlarge = (): void => {
    const {screenshot_url} = this.props;
    showPopup('qr-screenshot', <PopupDialog>
      <div className="popup__content simple-popup--big">
        <img className="popup-screenshot-qr" src={screenshot_url} alt={t('qr_for_payment')}/>
      </div>
    </PopupDialog>);
  };

  render(): ReactNode {
    const {screenshot_url} = this.props;
    return <div className="recipient-details">
      <div className="title-info">
        <span className="caption">{t('recipient_details')}</span>
        <div className="description">
          <p>{isDesktop() ? t('scan_the_qr') : t('scan_the_qr_mobile')}</p>
        </div>
      </div>
      {!isDesktop() && <a
        href={screenshot_url}
        className="button-adaptive button-adaptive_full-radius"
        target="_blank"
        rel="noreferrer"
        download
      >
        {t('save_screenshot')}
      </a>}
      <div className="screenshot-image">
        <div className="screenshot-image__open" onClick={this.enlarge}>
          <img src={screenshot_url} alt={t('qr_for_payment')}/>
          <div className="button-adaptive button-adaptive_crystal">
            <span className="icon">
              <svg>
                <use xlinkHref="#svg-zoom-icon"/>
              </svg>
            </span>
            <span className="name">{t('enlarge')}</span>
          </div>
        </div>
      </div>
    </div>;
  }
}

import React, {Component, ReactElement} from 'react';
import {OkButton} from 'components/ModalComponents/SubmitButton/OkButton';
import {CancelButton} from 'components/ModalComponents/SubmitButton/CancelButton';
import {Loader} from 'components/Loader/Loader';

interface SubmitButtonPropsInterface {
    title: string
    okClick(): void,
    className?: string,
    cancelClick?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void,
    cancelText?: string
}

interface SubmitButtonStateInterface {
    submitting: boolean
}

export class SubmitButton extends Component<SubmitButtonPropsInterface> {
  state: SubmitButtonStateInterface = {
    submitting: false
  };

  okClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    this.setState({submitting: true});
    this.props.okClick();
  };

  disabledClass = (): string => this.state.submitting ? ' button-adaptive_disable' : '';

  okButton = (): ReactElement => <OkButton
    okClick={this.okClick}
    title={this.props.title}
    disabledClass={this.disabledClass}
    submitting={this.state.submitting}/>;

  render(): ReactElement {
    const {cancelClick, cancelText} = this.props;
    const {submitting} = this.state;
    return <Loader
      flag={submitting}>
      {this.props.cancelClick
        ? <div className={this.props.className}>
          <CancelButton
            cancelClick={cancelClick}
            cancelText={cancelText}
            submitting={submitting}/>
          {this.okButton()}
        </div>
        : <div>
          {this.okButton()}
        </div>}
    </Loader>;
  }
}

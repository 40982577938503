import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {Provider} from 'mobx-react';
import Get from 'frontend_module/src/utils/fetch/Get';
import apiUrl from 'utils/apiUrl/apiUrl';
import {useParams} from 'react-router-dom';
import {P2pCashoutsStore} from 'stores/P2pCashoutsStore';
import {P2PCashoutInterface} from 'interfaces/P2PCashoutInterface';
import CurrentP2pCashout from 'components/CurrentP2pCashout/CurrentP2pCashout';

type StoresType = {
    p2pCashoutsStore?: P2pCashoutsStore
};

const Cashouts = (): ReactElement => {
  const p2pCashoutsStore = useRef<P2pCashoutsStore>();
  const [loaded, setLoading] = useState(false);
  const stores: StoresType = {
    p2pCashoutsStore: p2pCashoutsStore.current
  };
  const params = useParams<{id: string}>();

  useEffect(() => {
    if (p2pCashoutsStore.current) {
      setLoading(true);
      return;
    }
    new Get({url: apiUrl(`/v1/cashouts/${params.id}`)}).execute()
      .then(response => response.ok && response.json())
      .then((result: P2PCashoutInterface) => {
        p2pCashoutsStore.current = new P2pCashoutsStore(result);
        setLoading(true);
      });
  });
  return loaded
    ? <Provider {...stores}>
      <CurrentP2pCashout/>
    </Provider>
    : <></>;
};

export default Cashouts;

import React, {PureComponent} from 'react';
import OnClickToastInterface from 'interfaces/OnClickToastInterface';
import SvgSprite from 'utils/svgSprite/svgSprite';

export class CloseButton extends PureComponent<OnClickToastInterface> {
  onClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    this.props.closeToast && this.props.closeToast();
    this.props.onClick && this.props.onClick();
  };

  render(): React.ReactNode {
    return <button
      className="Toastify__close-button"
      type="button"
      aria-label="close"
      onClick={this.onClick}
    >
      <SvgSprite
        sprite_name={'svg-close'}
        height="24px"
        width="24px"/>
    </button>;
  }
}

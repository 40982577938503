import moment from 'moment';

const THIRTY_MINUTES = 30;

const remainingTimeUntil30Minutes = (date: string): boolean => {
  const future = moment(date).add(THIRTY_MINUTES, 'minutes');
  const now = moment();
  return Boolean(future.diff(now, 'seconds') > 0);
};

export default remainingTimeUntil30Minutes;

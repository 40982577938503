import React, {InputHTMLAttributes, memo} from 'react';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
}

const Checkbox = memo((props: IProps) => <div className="ofelos-pay-checkbox">
  <input
    className="ofelos-pay-checkbox-input"
    type="checkbox"
    {...props}
  />
  <label htmlFor={props.id} className="ofelos-pay-checkbox-icon"></label>
  <label htmlFor={props.id} className="ofelos-pay-checkbox-label">{props.label}</label>
</div>);

export default Checkbox;

import React, {PureComponent, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import SystemCard from 'components/SystemCard/SystemCard';
import HelpSupport from 'components/HelpSupport/HelpSupport';
import P2pDepositSteps from 'components/P2pDepositSteps/P2pDepositSteps';
import CurrentDeposit from 'components/CurrentDeposit/CurrentDeposit';
import P2pDepositsStoreProps from 'interfaces/P2pDepositsStoreProps';
import ModalsControlStoreProps from 'interfaces/ModalsControlStoreProps';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import {withTranslation, WithTranslation} from 'react-i18next';

@inject('p2pDepositsStore', 'modalsControlStore')
@observer
class CurrentP2pDeposit extends PureComponent {
  render(): ReactNode {
    const {
      modalsControlStore,
      p2pDepositsStore,
      p2pDepositsStore: {current_p2p_deposit: {
        system, state, verified_document, partner_name
      }},
      t
    } = this.props as WithTranslation & P2pDepositsStoreProps & ModalsControlStoreProps;
    return <>
      <div className="ofelos-pay-wrapper-title">
        <Breadcrumb p2pDepositsStore={p2pDepositsStore} modalsControlStore={modalsControlStore}/>
        <div className="ofelos-pay-title">
          <h1>{t('deposit')} {system.name} {t('on')} {partner_name}</h1>
        </div>
      </div>
      <div className="ofelos-pay-container">
        <div className="ofelos-pay-container-inner">
          <div className="ofelos-pay-payment-system">
            <SystemCard system={system}/>
          </div>
          <div className="ofelos-pay-wrapper-container">
            <CurrentDeposit/>
          </div>
        </div>
      </div>
      <P2pDepositSteps state={state} user_verified_document={verified_document}/>
      <HelpSupport/>
    </>;
  }
}

export default withTranslation()(CurrentP2pDeposit);

import React, {PureComponent, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import SystemCard from 'components/SystemCard/SystemCard';
import P2pCashoutsStoreProps from 'interfaces/P2pCashoutsStoreProps';
import CurrentCashout from 'components/CurrentCashout/CurrentCashout';
import CurrentScreenshotCashout from 'components/CurrentCashout/CurrentScreenshotCashout/CurrentScreenshotCashout';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import {withTranslation, WithTranslation} from 'react-i18next';
import HelpSupport from 'components/HelpSupport/HelpSupport';

@inject('p2pCashoutsStore')
@observer
class CurrentP2pCashout extends PureComponent {
  render(): ReactNode {
    const {
      p2pCashoutsStore,
      p2pCashoutsStore: {
        currentPaymentSystem,
        currentPaymentSystem: {payment_by_screenshot},
        current_p2p_cashout: {file, system, partner_name}
      },
      t
    } = this.props as WithTranslation & P2pCashoutsStoreProps;
    return <>
      <div className="ofelos-pay-wrapper-title">
        <Breadcrumb p2pCashoutsStore={p2pCashoutsStore}/>
        <div className="ofelos-pay-title">
          <h1>{t('cashout')} {system.name} {t('on')} {partner_name}</h1>
        </div>
      </div>
      <div className="ofelos-pay-container">
        <div className="ofelos-pay-container-inner">
          <div className="ofelos-pay-payment-system">
            <SystemCard system={currentPaymentSystem}/>
          </div>
          <div className="ofelos-pay-wrapper-container">
            {payment_by_screenshot && Boolean(file && !file.url)
              ? <CurrentScreenshotCashout/>
              : <CurrentCashout/>}
          </div>
        </div>
      </div>
      <HelpSupport/>
    </>;
  }
}

export default withTranslation()(CurrentP2pCashout);

import React, {Component, ReactElement} from 'react';

interface SubmitButtonPropsInterface {
    title: string
    okClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void
    disabledClass(): string
    submitting: boolean
}

export class OkButton extends Component<SubmitButtonPropsInterface> {
  render(): ReactElement {
    return <button type="button"
      className={`ofelos-pay-button${this.props.disabledClass()}`}
      disabled={this.props.submitting}
      onClick={this.props.okClick}>
      {this.props.title}
    </button>;
  }
}

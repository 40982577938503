import {action, makeAutoObservable, observable} from 'mobx';
import Currencies from 'utils/currencies/currencies';
import BankType from 'interfaces/BankType';
import SystemType from 'interfaces/SystemType';
import Get from 'frontend_module/src/utils/fetch/Get';
import Post from 'frontend_module/src/utils/fetch/Post';
import apiUrl from 'utils/apiUrl/apiUrl';
import {P2PCashoutInterface} from 'interfaces/P2PCashoutInterface';
import {toastError, toastSuccess} from 'components/Toasts/liteToast';
import {t} from 'i18next';
import {OK_STATUS} from 'utils/const/const';

export class P2pCashoutsStore {
    @observable current_p2p_cashout: P2PCashoutInterface;

    @observable submitting = false;

    @observable currentBank: BankType;

    @observable currentPaymentSystem: SystemType;

    @observable currency: keyof typeof Currencies = 'USD';

    constructor(cashout: P2PCashoutInterface) {
      makeAutoObservable(this);
      this.current_p2p_cashout = cashout;
      this.currentPaymentSystem = cashout.system;
      this.currentBank = cashout.wallet.bank;
    }

    @action update = (cashout: P2PCashoutInterface): void => {
      this.current_p2p_cashout = cashout;
      this.currentPaymentSystem = cashout.system;
      this.currentBank = cashout.wallet.bank;
    };

    loadCurrentP2pCashouts = (): void => {
      const {id} = this.current_p2p_cashout;
      new Get({url: apiUrl(`/v1/cashouts/${id}`)})
        .execute()
        .then(data => data.json())
        .then(data => this.update(data));
    };

    sendDocuments = (formData: () => FormData, afterSave?: () => void): void => {
      const {id} = this.current_p2p_cashout;
      fetch(apiUrl(`/v1/cashouts/${id}/payment_file`), {
        body: formData(),
        method: 'POST'
      })
        .then(response => {
          if (response.status === OK_STATUS) {
            toastSuccess(t('successfully_uploaded'));
            this.loadCurrentP2pCashouts();
          } else {
            toastError(t('uploaded_file_too_large'));
          }
          afterSave && afterSave();
        });
    };

    notWalidCashout = (): void => {
      const {id} = this.current_p2p_cashout;
      new Post({url: apiUrl(`/v1/cashouts/${id}/invalid`)})
        .execute()
        .then(response => {
          if (response.status === OK_STATUS) {
            toastSuccess(t('successfully'));
          } else {
            toastError(t('server_error_try_later'));
          }
          this.loadCurrentP2pCashouts();
        });
    };

    acceptTransferCashout = (): void => {
      const {id} = this.current_p2p_cashout;
      new Post({url: apiUrl(`/v1/cashouts/${id}/accept_transfer`)})
        .execute()
        .then(response => {
          if (response.status === OK_STATUS) {
            toastSuccess(t('successfully'));
          } else {
            toastError(t('server_error_try_later'));
          }
          this.loadCurrentP2pCashouts();
        });
    };
}

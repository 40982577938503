import React from 'react';
import SmartLoader from 'components/reusable/SmartLoader';

interface LoandingPropsInterface {
    children: JSX.Element
    flag: boolean
}

export const Loader = (
  props: LoandingPropsInterface
): JSX.Element => props.flag ? <SmartLoader/> : props.children;

import {action, observable} from 'mobx';
import {AddConfirmModalInterface} from 'components/ModalComponents/ModalContainer/AddConfirmModalInterface';

export class ModalsControlStore {
  constructor(modalConfirm?: ({text, onOkClick, onCancel}: AddConfirmModalInterface) => void) {
    this.modalConfirm = modalConfirm;
  }

  @observable modalConfirm?: (({text, onOkClick, onCancel}: AddConfirmModalInterface) => void);

  @observable closeModal?: () => void;

  @action setModal(modalConfirm: ({text, onOkClick, onCancel}: AddConfirmModalInterface) => void): void {
    this.modalConfirm = modalConfirm;
  }

  @action setCloseModal(closeModal: () => void): void {
    this.closeModal = closeModal;
  }
}

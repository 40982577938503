import React, {ReactElement} from 'react';
import {StatusBarButton} from 'components/CurrentCashout/StatusBar/StatusBarButton/StatusBarButton';
import {useTranslation} from 'react-i18next';

interface ScreenshotLoaderProps {
    state: string
    cashout?: boolean
    className?: string
    verified?: boolean
}

export const StatusBar = (props: ScreenshotLoaderProps): ReactElement => {
  const {t} = useTranslation();
  const {className, state, cashout, verified} = props;
  const notValidDoc = () => state === 'not_valid' && verified ? '_doc' : '';
  return <div className={`ofelos-pay-status-message ${className ? className : ''}`}>
    <div className="description">
      {t(`${cashout ? 'cashout' : 'deposit'}_${state}${notValidDoc()}`)}
    </div>
    <StatusBarButton state={state}/>
  </div>;
};

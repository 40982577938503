import I18n from 'I18n/i18n';
import TranslationsType from 'interfaces/TranslationsType';

export type WithTranslation<T, TranslationsKey extends string> = T &
  {[key in TranslationsKey]: TranslationsType};

const translated = <T, TranslationsKey extends string>(
  object: WithTranslation<T, TranslationsKey>,
  translationsKey: TranslationsKey
): string => {
  const translations = object[translationsKey];
  return translations && translations[I18n.language as keyof TranslationsType];
};

export default translated;

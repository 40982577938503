import React, {ReactElement} from 'react';
import Sprite from 'styles/sprites/svg_sprite.svg';
import {svgSpriteInterface} from 'utils/svgSprite/svgSpriteInterface';

const SvgSprite = ({sprite_name, classNames, height, width}: svgSpriteInterface): ReactElement => <svg
  height={height || '100%'}
  width={width || '100%'}
  className={classNames ? classNames : 'svg-default' }>
  <use href={`${Sprite}#${sprite_name}`} />
</svg>;

export default SvgSprite;

import React, {ReactElement} from 'react';
import SvgSprite from 'utils/svgSprite/svgSprite';
import {useTranslation} from 'react-i18next';

interface StatusBarButtonProps {
    state: string
}

export const StatusBarButton = (props: StatusBarButtonProps): ReactElement => {
  const {t} = useTranslation();
  const {state} = props;
  return <div className="ofelos-pay-label-button">
    <span className="icon">
      <SvgSprite sprite_name={`svg-${state}`}/>
    </span>
    <span className="name">{t(`button_${state}`)}</span>
  </div>;
};
